import { Controller } from "@hotwired/stimulus";

const EDIT_AUDIENCE_PATH_REGEX = /^\/admin\/audiences\/(\d+)\/edit$/;
const NO_SCROLL_CLASS = "no-scroll";

export default class extends Controller {
  static targets = ["audienceSelect", "createAudienceModal", "createAudienceIframe"];
  static values = { newAudienceUrl: String };

  connect() {
    this.body = document.querySelector("body");
  }

  dismiss() {
    this.createAudienceModalTarget.classList.remove("active");
    this.createAudienceIframeTarget.src = "";
    this.body.classList.remove(NO_SCROLL_CLASS);
  }

  createAudience() {
    this.createAudienceModalTarget.classList.add("active");
    this.createAudienceIframeTarget.src = this.newAudienceUrlValue;
    this.body.classList.add(NO_SCROLL_CLASS);
  }

  onIframeNavigation() {
    const iframeContentWindow = this.createAudienceIframeTarget.contentWindow;
    const audienceWasCreatedMatch =
      iframeContentWindow.location.pathname.match(EDIT_AUDIENCE_PATH_REGEX);

    if (!audienceWasCreatedMatch) {
      return;
    }

    const audienceId = audienceWasCreatedMatch[1];

    const option = document.createElement("option");
    option.text = iframeContentWindow.document.querySelector("h1").innerText;
    option.setAttribute("selected", true);
    option.setAttribute("value", audienceId);

    this.audienceSelectTarget.insertBefore(option, this.audienceSelectTarget.options[0]);

    this.dismiss();
  }
}
