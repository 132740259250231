import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { message: String };

  confirmCancel(event) {
    // temporarily use a confirm dialog until we have a modal design for this situation
    if (!confirm(this.messageValue)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
