import { datadogLogs } from "@datadog/browser-logs";

const isNonReportingEnv =
  process.env.ENVIRONMENT === "development" || process.env.ENVIRONMENT === "test";

// Don't initialize browser logs in development or test environments
if (process.env.NODE_ENV === "production" && !isNonReportingEnv) {
  datadogLogs.init({
    clientToken: "pub9fb9300e06308781c27de6194d49aa0d",
    env: "production",
    service: "browser",
    silentMultipleInit: true, // Disable console logs in case the SDK is already initialized
  });
}

export default datadogLogs.logger;
