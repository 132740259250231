export const formatPhoneNumber = (event) => {
  const element = event.target;
  element.value = element.value
    .replace(/\D/g, "")
    .slice(0, 10)
    .replace(/(\d{1,3})(\d{0,3})(\d{0,4})/, "($1)$2-$3")
    .replace(/[()-]+$/g, "");

  return element.value;
};

export const formatLicensePlate = ({ target }) => {
  target.value = target.value
    .replace(/[^0-9A-z]/g, "")
    .toUpperCase()
    .substring(0, 8);
};
