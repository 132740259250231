import { Controller } from "@hotwired/stimulus";
import { getOpenEdgeFormStyles } from "../lib/open_edge/styles";

// Connects to data-controller="open-edge"
export default class extends Controller {
  static outlets = ["payment-update"];
  static targets = ["cvcError", "expiryError", "cardNumberError", "temporaryToken"];

  static values = {
    globalPaymentsApiPublicKey: String,
    openEdgeEnv: String,
    embeddedInCustomerPortal: Boolean,
    customerPortalStyles: Object,
    isCheckout: Boolean,
  };

  connect() {
    GlobalPayments.configure({
      "X-GP-Api-Key": this.globalPaymentsApiPublicKeyValue,
      "X-GP-Environment": this.openEdgeEnvValue,
    });

    const formStyles = getOpenEdgeFormStyles({
      embeddedInCustomerPortal: this.embeddedInCustomerPortalValue,
      customerPortalButtonColor: this.customerPortalStylesValue.button_color,
      customerPortalButtonTextColor: this.customerPortalStylesValue.button_text_color,
    });
    const cardForm = GlobalPayments.ui.form({
      fields: {
        "card-number": {
          placeholder: "•••• •••• •••• ••••",
          target: "#card-number",
        },
        "card-expiration": {
          placeholder: "MM / YYYY",
          target: "#card-expiration",
        },
        "card-cvv": {
          placeholder: "CVC",
          target: "#card-cvv",
        },
        submit: {
          target: "#open-edge-submit-button",
          text: this.isCheckoutValue ? "Checkout" : "Update Payment Method",
        },
      },
      ...formStyles,
    });
    cardForm.on("token-success", this.handleTokenSuccess.bind(this));
    cardForm.on("token-error", this.handleTokenError.bind(this));
  }

  async handleTokenSuccess(response) {
    this.paymentUpdateOutlet.setCustomError(null);
    this.cvcErrorTarget.hidden = true;
    this.expiryErrorTarget.hidden = true;
    this.cardNumberErrorTarget.hidden = true;
    this.temporaryTokenTarget.value = response.temporary_token;
    // hide the open edge submit button (since we can't disable it) and display our own disabled one
    document.getElementById("open-edge-submit-button").hidden = true;
    this.paymentUpdateOutlet.showSubmit();
    // submit the form
    this.paymentUpdateOutlet.formRequestSubmit();
  }

  handleTokenError(response) {
    const {
      error: { code, detail },
    } = response;

    let errorMessage = null;
    let isCardCvcValid = true;
    let isCardExpiryValid = true;
    let isCardNumberValid = true;

    if (code === "invalid_input") {
      detail.forEach((error) => {
        const field = error.data_path;
        if (field.match("/card/card-number")) isCardNumberValid = false;
        if (field.match("/card/expiry")) isCardExpiryValid = false;
        if (field.match("/card/card_security_code")) isCardCvcValid = false;
      });
    } else if (code === "invalid_card") {
      isCardNumberValid = false;
    } else {
      errorMessage = "There was a problem verifying your credit card. Please try again later.";
    }

    this.paymentUpdateOutlet.setCustomError(errorMessage);

    this.cvcErrorTarget.hidden = isCardCvcValid;
    this.expiryErrorTarget.hidden = isCardExpiryValid;
    this.cardNumberErrorTarget.hidden = isCardNumberValid;
  }
}
