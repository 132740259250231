// Requires a dom element to render the recaptcha in, and a recaptcha site key.
// Returns an async "execute" function that executes the recaptcha and returns the token.
export function setupRecaptcha(recaptchaElement, sitekey) {
  console.log("Setting up ReCAPTCHA from helpers...");
  let captchaResolve;

  window.onRecaptchaLoad = () => {
    grecaptcha.render(recaptchaElement, {
      sitekey,
      size: "invisible",
      callback: (token) => captchaResolve(token),
    });
  };

  console.log("Added onRecaptchaLoad to window");

  const script = document.createElement("script");
  script.src = "https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit";
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);

  console.log("Loaded ReCAPTCHA Google script");

  return async () => {
    console.log("Beginning ReCAPTCHA execute");
    try {
      const token = await new Promise((resolve) => {
        console.log("Executing ReCAPTCHA with updated resolve");
        captchaResolve = resolve;
        grecaptcha.execute();
      });
      return token;
    } finally {
      try {
        grecaptcha.reset();
      } catch (error) {
        console.error("grecaptcha.reset() error:", error);
      }
    }
  };
}
