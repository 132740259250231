import { datadogRum } from '@datadog/browser-rum';

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '8e07c8e1-beab-44d7-8532-12568505eb2c',
    clientToken: 'pub23a3762acfea01c585e90f9cce2db2d7',
    sessionSampleRate: 1,
    sessionReplaySampleRate: 1,
    service:'web',
    site: 'datadoghq.com',
    trackInteractions: true,
    version: "",
  });
  datadogRum.startSessionReplayRecording();
}
