import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  close() {
    // this.element is the DOM element that the controller is attached to.
    //
    // remove the src attribute from the '<turbo-frame id="modal"></turbo-frame>' HTML element.
    this.element.parentElement.removeAttribute("src");
    // remove the modal element from the DOM.
    this.element.remove();
  }
}
