import { post } from "@rails/request.js";

export const create = (name, payload, options = {}) => {
  const anonymousId = document.querySelector("body").dataset.anonymous_id;

  return post(`${options.admin ? "/admin" : ""}/events.json`, {
    contentType: "application/json",
    body: JSON.stringify({
      event: {
        anonymous_id: anonymousId,
        name,
        payload,
      },
    }),
  });
};
