import EventController from "../event_controller";

export default class extends EventController {
  static targets = [
    "barcode",
    "ccLast4",
    "lastName",
    "licensePlateNumber",
    "licensePlateState",
    "phoneOrEmail",
  ];

  eventProperties(baseProperties, _eventType, node, _target) {
    return { ...baseProperties, ...this.#loginCredentials() };
  }

  #loginCredentials() {
    const credentials = {};

    if (this.hasBarcodeTarget) {
      credentials.barcode = this.barcodeTarget.value;
    }

    if (this.hasCcLast4Target) {
      credentials.cc_last_4 = this.ccLast4Target.value;
    }

    if (this.hasLastNameTarget) {
      credentials.last_name = this.lastNameTarget.value;
    }

    if (this.hasLicensePlateNumberTarget) {
      credentials.license_plate_number = this.licensePlateNumberTarget.value;
    }

    if (this.hasLicensePlateStateTarget) {
      credentials.license_plate_state = this.licensePlateStateTarget.value;
    }

    if (this.hasPhoneOrEmailTarget) {
      credentials.phone_or_email = this.phoneOrEmailTarget.value;
    }

    return { login_credentials: credentials };
  }
}
