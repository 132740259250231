import { Controller } from "@hotwired/stimulus";
import { formatPhoneNumber } from "../../formatters";

const phoneRegex = /^\(\d{3}\)\d{3}-\d{4}$/;

export default class extends Controller {
  static targets = ["optInToSmsContainer", "phoneOrEmail"];

  format(event) {
    const element = event.target;

    // Remove LTR and PDF characters that are inserted by the browser when
    // copy-pasting the phone number from Google Voice UI.
    const value = element.value.replace(/[\u202A\u202C]/g, "").replace(" ", "");

    // match a string with exactly 10 digits
    if (value.match(/^\d{10}$/)) {
      formatPhoneNumber(event);
    }
    // match the formatted phone string with 1 or more characters after the last digit
    else if (value.match(/^\(\d{3}\)\d{3}-\d{4}.+/)) {
      const first13Chars = value.substring(0, 13);
      const cleanedFirst13Chars = first13Chars.replace(/[()-]/g, "");

      element.value = cleanedFirst13Chars + value.substring(13);
    }
    // set sanitized value for both phone and email
    else {
      element.value = value;
    }

    if (this.#isPhoneNumber(element.value)) {
      this.optInToSmsContainerTarget.classList.remove("hidden");
    } else {
      this.optInToSmsContainerTarget.classList.add("hidden");
    }
  }

  #isPhoneNumber(value) {
    return phoneRegex.test(value);
  }
}
