import { Controller } from "@hotwired/stimulus";

const OK_CODE = "001";
const DEFAULT_ERROR_MESSAGE =
  "An error occured. Please check your credit card information and try again.";

class HostedMonerisController extends Controller {
  static outlets = ["payment-update"];
  static values = {
    monerisTokenizeUrl: String,
    monerisIframeDesktopUrl: String,
    monerisIframeMobileUrl: String,
  };
  static targets = ["monerisIframe", "dataKey", "responseCode", "errorMessage"];

  static MOBILE_GRID_BREAKPOINT = 768;

  connect() {
    this.#setIframeUrl();

    window.addEventListener("message", this.onTokenizeComplete.bind(this), false);

    this.paymentUpdateOutlet.addAsyncSubmitInterrupt(this.handleSubmit.bind(this));
    this.paymentUpdateOutlet.enableSubmit();
  }

  handleSubmit() {
    // If we're still waiting on a tokenization response, we'll already have a pending resolve.
    // Resolve with a failure before proceeding.
    if (this.pendingResolve) {
      this.#resolvePendingPromise(false);
    }

    this.monerisIframeTarget.contentWindow.postMessage("tokenize", this.monerisTokenizeUrlValue);

    // The promise will get resolved when we receive the tokenize completion message
    // from the iFrame
    return new Promise((resolve, _reject) => (this.pendingResolve = resolve));
  }

  onTokenizeComplete(event) {
    if (
      !this.monerisIframeTarget.src.startsWith(event.origin) ||
      !event.isTrusted ||
      !this.pendingResolve
    ) {
      return;
    }

    const {
      errorMessage,
      dataKey,
      responseCode: responseCodeStringOrArray,
    } = JSON.parse(event.data);
    const responseCodeArray = [].concat(responseCodeStringOrArray);
    const responseCodeString = responseCodeArray[0];

    if (responseCodeString && responseCodeString !== OK_CODE) {
      return this.#tokenizationError(this.#errorMessagerForCode(responseCodeString));
    }

    if (errorMessage) {
      return this.#tokenizationError(errorMessage);
    }

    if (!dataKey || !responseCodeString) {
      return this.#tokenizationError(DEFAULT_ERROR_MESSAGE);
    }

    this.dataKeyTarget.value = dataKey;
    this.responseCodeTarget.value = JSON.stringify(responseCodeArray);
    this.#resolvePendingPromise(true);
  }

  #tokenizationError(errorMessage) {
    this.paymentUpdateOutlet.setCustomError(errorMessage);
    this.#resolvePendingPromise(false);
  }

  #resolvePendingPromise(value) {
    this.pendingResolve(value);
    this.pendingResolve = null;
  }

  #setIframeUrl() {
    const isMobile =
      this.monerisIframeTarget.clientWidth < HostedMonerisController.MOBILE_GRID_BREAKPOINT;

    this.monerisIframeTarget.src = isMobile
      ? this.monerisIframeMobileUrlValue
      : this.monerisIframeDesktopUrlValue;
  }

  #errorMessagerForCode(code) {
    const target = this.errorMessageTargets.find((el) => el.dataset.code === code);

    return target ? target.dataset.message : DEFAULT_ERROR_MESSAGE;
  }
}

export default HostedMonerisController;
