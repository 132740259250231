import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    // All target entries are objects with shape:
    // {
    //   selector: "id", // can be expanded to class etc in the future
    //   value: "foo-element", // the value of the selector
    //   eventName: "event name", // the event name to emit to posthog
    // }
    //
    // For example:
    // {
    //   selector: "id",
    //   value: "new-song-button",
    //   eventName: "new_song_button_clicked",
    // }
    clickTargets: Array,
  };

  static eventListenerOptions = { passive: true, capture: true };
  static registeredHandlers = {};

  connect() {
    if (!window.posthog) {
      return;
    }

    if (this.clickTargetsValue?.length) {
      document.addEventListener(
        "click",
        this.#eventHandler("click"),
        this.constructor.eventListenerOptions
      );
    }
  }

  disconnect() {
    Object.entries(this.constructor.registeredHandlers).forEach(([event, handler]) => {
      document.removeEventListener(event, handler, this.constructor.eventListenerOptions);
    });
  }

  // Override these methods in your subclassed controller **********************

  shouldCaptureEvent(_type, _node, _target) {
    return true;
  }

  eventProperties(baseProperties, _eventType, _node, _target) {
    return baseProperties;
  }

  // ***************************************************************************

  #extractTargetFromEvent(event) {
    let node = event.target;

    while (node) {
      const targetMatch = this.#findMatchingTargetForNode(node, this.clickTargetsValue);
      if (targetMatch) {
        return { node, target: targetMatch };
      }

      node = node.parentNode;
    }

    return { node: null, target: null };
  }

  #findMatchingTargetForNode(node, targets) {
    for (const target of targets) {
      const { selector, value } = target;
      if (selector === "id" && node.id === value) {
        return target;
      } else if (selector === "class" && node.classList && node.classList.contains(value)) {
        return target;
      }
    }

    return null;
  }

  #eventHandler(type) {
    const handler = (event) => {
      const { node, target } = this.#extractTargetFromEvent(event);
      if (target && this.shouldCaptureEvent(type, node, target)) {
        posthog.capture(target.eventName, this.#generateEventProperties(type, node, target));
      }
    };

    this.constructor.registeredHandlers[type] = handler;

    return handler;
  }

  #generateEventProperties(type, node, target) {
    const { user_email, user_roles } = window.posthogRinsedGlobals || {};
    return this.eventProperties({ user_email, user_roles }, type, node, target);
  }
}
