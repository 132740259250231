import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = { path: String };

  update(event) {
    get(`${this.pathValue}?replication_group_id=${event.target.value}`, {
      responseKind: "turbo-stream",
    });
  }
}
