import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dateContainerTemplate", "datesContainer"];

  addDate() {
    const newDateContainer = this.dateContainerTemplateTarget.cloneNode(true);

    delete newDateContainer.dataset["memberOfferPlans-Wizard-ScheduledMessagesTarget"];
    newDateContainer.classList.remove("hidden");
    this.datesContainerTarget.appendChild(newDateContainer);
  }

  removeDate(e) {
    let dateContainer = e.target;
    while (dateContainer && !dateContainer.classList.contains("date-container")) {
      dateContainer = dateContainer.parentNode;
    }

    if (dateContainer) {
      dateContainer.remove();
    }
  }
}
