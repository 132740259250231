import { Controller } from "@hotwired/stimulus";
import { loadVGSCollect } from "@vgs/collect-js";
import logger from "../logger";

// Connects to data-controller="vgs"
export default class extends Controller {
  static outlets = ["payment-update"];
  static values = {
    vgsEnv: String,
    vgsFormSubmitUrl: String,
    vgsVaultId: String,
  };

  static targets = [
    "cardNumberError",
    "cardExpiryError",
    "cardCvcError",
    "cardBrand",
    "cardCvc",
    "cardLast4",
    "cardExpiry",
    "cardNumber",
  ];

  async connect() {
    this.paymentUpdateOutlet.addAsyncSubmitInterrupt(this.handleSubmit.bind(this));

    // load script
    const collect = await loadVGSCollect({
      vaultId: this.vgsVaultIdValue,
      environment: this.vgsEnvValue,
      version: "2.24.6",
    });

    // https://www.npmjs.com/package/@vgs/collect-js#how-to-use
    this.vgsForm = collect.init((state) => {
      this.vgsFormState = state;
    });

    this.vgsForm.on("enterPress", () => this.paymentUpdateOutlet.formRequestSubmit());

    if (typeof this.vgsForm.setLoadingTimeout === "function") {
      this.vgsForm.setLoadingTimeout(6000); // warning: this function is undocumented
    }

    const vgsFieldCss = {
      backgroundColor: "white",
      border: "1px solid #E4E7EC", // gray-300
      borderRadius: "8px",
      fontSize: "1rem",
      padding: "0 15px",
      "&.invalid.touched": {
        borderColor: "#F04438", // error-500
      },
    };

    const cardNumberField = this.vgsForm.field("#cc-number", {
      autoComplete: "cc-number",
      css: { ...vgsFieldCss, paddingLeft: "50px" },
      name: "card_number",
      placeholder: "•••• •••• •••• ••••",
      showCardIcon: { left: "10px" },
      type: "card-number",
      validations: ["required", "validCardNumber"],
    });
    cardNumberField.on("keydown", () => {
      this.cardNumberErrorTarget.hidden = true;
    });

    const cardExpiryField = this.vgsForm.field("#cc-expiration-date", {
      autoComplete: "cc-exp",
      css: {
        ...vgsFieldCss,
        height: "38px",
        width: "calc(100% - 33px)",
      },
      name: "card_expiry",
      placeholder: "MM / YY",
      type: "card-expiration-date",
      validations: ["required", "validCardExpirationDate"],
      yearLength: "2",
    });
    cardExpiryField.on("keydown", () => {
      this.cardExpiryErrorTarget.hidden = true;
    });

    const cardCvcField = this.vgsForm.field("#cc-cvc", {
      autoComplete: "cc-csc",
      css: vgsFieldCss,
      maxLength: 4,
      name: "card_cvc",
      placeholder: "CVC",
      showCardIcon: { right: "10px" },
      type: "card-security-code",
      validations: ["required", "validCardSecurityCode"],
    });
    cardCvcField.on("keydown", () => {
      this.cardCvcErrorTarget.hidden = true;
    });

    this.paymentUpdateOutlet.enableSubmit();
  }

  handleSubmit() {
    return new Promise((resolve) => {
      this.vgsForm.submit(
        this.vgsFormSubmitUrlValue,
        {},
        (status, response) => {
          this.vgsResponseCallback(status, response, resolve);
        },
        (errors) => {
          this.vgsValidationCallback(errors, resolve);
        }
      );
    });
  }

  vgsResponseCallback = (status, response, resolve) => {
    const { card_cvc, card_expiry, card_number } = response.json || {};
    const { cardType, last4 } = this.vgsFormState.card_number;

    logger.info("VGS response received", { cardType, last4, response, status });

    if (response === "Network Error") {
      // This is a network connection error. Most likely the user is unable to access VGS for some
      // reason. E.g. blocked by an office network firewall, or a more transient failure.
      this.cardNumberErrorTarget.hidden = false;
      this.cardNumberErrorTarget.textContent =
        "We experienced a connection issue while processing your credit card. " +
        "Please reach out to us to complete your purchase or visit one of our locations.";
      resolve(false);
    } else {
      this.cardNumberErrorTarget.hidden = true;
      this.cardExpiryErrorTarget.hidden = true;
      this.cardCvcErrorTarget.hidden = true;

      this.cardBrandTarget.value = cardType;
      this.cardCvcTarget.value = card_cvc;
      this.cardExpiryTarget.value = card_expiry.replace(/\D/g, "").padStart(4, 0);
      this.cardLast4Target.value = last4;
      this.cardNumberTarget.value = card_number;
      resolve(true);
    }
  };

  vgsValidationCallback = (cardErrors, resolve) => {
    const errors = {};
    Object.keys(cardErrors).forEach((property) => {
      errors[property] = cardErrors[property].errorMessages[0];
    });

    this.cardNumberErrorTarget.hidden = !errors.card_number;
    this.cardNumberErrorTarget.textContent = errors.card_number;

    this.cardExpiryErrorTarget.hidden = !errors.card_expiry;
    this.cardExpiryErrorTarget.textContent = errors.card_expiry;

    this.cardCvcErrorTarget.hidden = !errors.card_cvc;
    this.cardCvcErrorTarget.textContent = errors.card_cvc;

    resolve(false);
  };
}
