import { Controller } from "@hotwired/stimulus";
import { formatPhoneNumber } from "../formatters";

export default class extends Controller {
  static values = { invalidPhoneNumbers: Array };
  static targets = ["phone"];

  format(event) {
    formatPhoneNumber(event);
  }

  validate() {
    if (this.invalidPhoneNumbersValue.includes(this.phoneTarget.value)) {
      this.phoneTarget.setCustomValidity("This phone number is invalid.");
      this.phoneTarget.reportValidity();
    } else {
      this.phoneTarget.setCustomValidity("");
    }
  }
}
