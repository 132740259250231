import compact from "lodash/compact";
import merge from "lodash/merge";
import isObject from "lodash/isObject";
import googleTagManager from "@analytics/google-tag-manager";
import { create as createEvent } from "./events";
import Analytics from "analytics";

const { google_container_id } = document.querySelector("body").dataset;

const analytics = Analytics({
  app: "web",
  plugins: compact([
    google_container_id ? googleTagManager({ containerId: google_container_id }) : null,
  ]),
});

const properties = () => {
  const parentUrl = document.querySelector('input[name="parent_url"]').value || null;
  const referrer = document.querySelector('input[name="referrer"]').value || null;

  return {
    frame_url: location.href,
    parent_url: parentUrl,
    referrer: referrer,
    url: parentUrl,
  };
};

const adminProperties = () => {
  return {
    referrer: document.referrer,
  };
};

export const emitPageLoadEvent = (options = {}) => {
  createEvent("Page loaded", options.admin ? adminProperties() : properties(), options);
};

export const page = (params, referrer) => {
  emitPageLoadEvent();

  const payload = merge(properties(), {
    referrer,
    campaign: {
      adset: params.get("utm_adset"),
      content: params.get("utm_content"),
      medium: params.get("utm_medium"),
      name: params.get("utm_campaign"),
      source: params.get("utm_source") || params.get("referrer"),
      term: params.get("utm_term"),
    },
  });

  if (google_container_id) analytics.page(payload);
};

export const track = (event, payload = {}) => {
  if (!event) return;

  const mergedProperties = merge(properties(), payload);

  createEvent(isObject(event) ? event.default : event, mergedProperties);
};
