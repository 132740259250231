import { Controller } from "@hotwired/stimulus";

const ACTIVE_CLASS = "active";

export default class extends Controller {
  static targets = ["section", "header"];

  connect() {
    this._boundClickHandler = this.toggleCollapsibleContent.bind(this);
    this.headerTarget.addEventListener("click", this._boundClickHandler);
  }

  disconnect() {
    document.removeEventListener("click", this._boundClickHandler);
  }

  toggleCollapsibleContent() {
    if (this.sectionTarget.classList.contains(ACTIVE_CLASS)) {
      this.sectionTarget.classList.remove(ACTIVE_CLASS);
    } else {
      this.sectionTarget.classList.add(ACTIVE_CLASS);
    }
  }
}
