import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "barcode",
    "barcodeContainer",
    "licensePlateNumber",
    "licensePlateNumberContainer",
    "licensePlateState",
  ];

  barcodeOrLicensePlateNumberChange(event) {
    const { value } = event.target;

    this.barcodeContainerTarget.classList.add("hidden");
    this.barcodeTarget.disabled = true;

    this.licensePlateNumberContainerTarget.classList.add("hidden");
    this.licensePlateNumberTarget.disabled = true;
    this.licensePlateStateTarget.disabled = true;

    this.#removeErrors();

    if (value === "barcode") {
      this.barcodeContainerTarget.classList.remove("hidden");
      this.barcodeTarget.disabled = false;
    }

    if (value === "license_plate_number") {
      this.licensePlateNumberContainerTarget.classList.remove("hidden");
      this.licensePlateNumberTarget.disabled = false;
      this.licensePlateStateTarget.disabled = false;
    }
  }

  #removeErrors() {
    this.barcodeTarget.classList.remove("is-danger");
    this.licensePlateNumberTarget.classList.remove("is-danger");

    const errors = document.querySelectorAll("p.help.is-danger");

    errors.forEach((error) => {
      if (
        this.barcodeContainerTarget.contains(error) ||
        this.licensePlateNumberContainerTarget.contains(error)
      ) {
        error.remove();
      }
    });
  }
}
