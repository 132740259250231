import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "list"];

  add() {
    const template = this.templateContent();
    this.listTarget.insertAdjacentHTML("beforeend", template);
  }

  remove(event) {
    const row = event.target.closest("tr");
    const index = Array.from(this.fieldTargets).indexOf(row);

    row.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="contact_form[custom_fields_attributes][${index}][_destroy]" value="1" />`
    );
    row.style.display = "none";
  }

  templateContent() {
    const index = this.fieldTargets.length;
    return `
      <tr data-custom-fields-target="field">
        <td><input type="text" name="contact_form[custom_fields_attributes][${index}][label]" /></td>
        <td><input type="text" name="contact_form[custom_fields_attributes][${index}][code]" /></td>
        <td><input type="text" name="contact_form[custom_fields_attributes][${index}][placeholder_text]" /></td>
        <td><input type="checkbox" name="contact_form[custom_fields_attributes][${index}][required]" /></td>
        <td>
          <button type="button" data-action="custom-fields#remove">
            Delete
          </button>
        </td>
      </tr>
    `;
  }
}
