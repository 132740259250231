import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "input"];

  connect() {
    this.updateValue();
  }

  toggleInput() {
    this.inputTarget.disabled = !this.toggleTarget.checked;
    this.updateValue();
  }

  updateValue() {
    if (!this.toggleTarget.checked) {
      this.inputTarget.value = null;
    } else {
      this.inputTarget.value ||= "0";
    }
  }
}
