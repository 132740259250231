export function getOpenEdgeFormStyles({
  embeddedInCustomerPortal,
  customerPortalButtonColor,
  customerPortalButtonTextColor,
}) {
  const buttonStyles = embeddedInCustomerPortal
    ? {
        button: {
          "background-color": customerPortalButtonColor,
          "border-radius": "8px",
          width: "100% !important",
          height: "40px",
          "font-size": "0.875rem",
          "font-weight": "600",
          color: customerPortalButtonTextColor,
        },
        "button:focus": {
          border: "1px solid transparent",
          "box-shadow": "0 0 0 0.125em rgba(0, 144, 219, 0.25);",
          outline: "none",
        },
      }
    : {
        button: {
          "background-color": "#315FD8", // brand-600
          border: "1px solid white",
          "border-radius": "8px",
          color: "white",
          flex: "none !important",
          "font-size": "16px",
          height: "40px",
          "line-height": "24px",
          padding: "7px 16px",
          width: "225px !important",
        },
        "button:active": { "background-color": "#224AB4" }, // brand-700
        "button:hover": { "background-color": "#224AB4", cursor: "pointer" }, // brand-700
        "button:focus": {
          border: "1px solid transparent",
          "box-shadow": "0 0 0 0.125em rgba(0, 144, 219, 0.25);",
          outline: "none",
        },
      };

  return {
    styles: {
      body: {
        "font-family":
          'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
        "font-weight": "500",
      },
      ...buttonStyles,
      input: {
        "-webkit-font-smoothing": "antialiased",
        border: "1px solid transparent",
        "border-color": "#D0D5DD", // gray-300
        "border-radius": "8px",
        "box-shadow": "inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05)",
        "font-size": "1rem",
        height: "24px",
        padding: "7px 11px",
      },
      "input:focus": {
        "border-color": "#98AFEB", // brand-300
        outline: "2px solid transparent",
        "box-shadow": "0 0 0 2px #D4DDF7", // brand-100
      },
      "input.invalid": {
        border: "1px solid #dc2626", // red-600
      },
    },
  };
}
