import { Controller } from "@hotwired/stimulus";

const ACTIVE_CLASS = "active";

export default class extends Controller {
  static targets = ["previewItem", "goToButton"];

  previousItem() {
    const activeItemIndex = this.#activeItemIndex();
    this.#setActiveItem(activeItemIndex, activeItemIndex - 1);
  }

  nextItem() {
    const activeItemIndex = this.#activeItemIndex();
    this.#setActiveItem(activeItemIndex, activeItemIndex + 1);
  }

  goToItem(e) {
    const { target } = e;
    const index = +("index" in target.dataset ? target : target.parentNode).dataset.index;
    this.#setActiveItem(this.#activeItemIndex(), index);
  }

  #activeItemIndex() {
    return this.previewItemTargets.findIndex((el) => el.classList.contains(ACTIVE_CLASS));
  }

  #setActiveItem(currentActiveIndex, newActiveIndex) {
    const currentActiveItem = this.previewItemTargets[currentActiveIndex];
    const newActiveItem = this.previewItemTargets[newActiveIndex];

    if (currentActiveItem && newActiveItem) {
      this.#toggleActive(false, currentActiveItem, this.goToButtonTargets[currentActiveIndex]);
      this.#toggleActive(true, newActiveItem, this.goToButtonTargets[newActiveIndex]);
    }
  }

  #toggleActive(state, ...elements) {
    elements.forEach((el) => el.classList.toggle(ACTIVE_CLASS, state));
  }
}
