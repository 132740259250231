import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "currentTotals",
    "newTotals",
    "newMonthlyTotal",
    "newVehiclesCount",
    "planCount",
  ];

  static values = {
    currentMonthlyTotal: Number,
    currentVehicleCount: Number,
    planPrices: String,
  };

  calculateNewTotals() {
    const planPrices = JSON.parse(this.planPricesValue);

    const additionalCount = this.planCountTargets.reduce((acc, planCountElement) => {
      const parsedCount = parseInt(planCountElement.value);
      const count = isNaN(parsedCount) ? 0 : parsedCount;

      return acc + count;
    }, 0);

    const additionalTotal = this.planCountTargets.reduce((acc, planCountElement) => {
      const planId = planCountElement.dataset.planId;
      const parsedCount = parseInt(planCountElement.value);
      const count = isNaN(parsedCount) ? 0 : parsedCount;
      const total = (count * planPrices[planId] * 100) / 100; // round to 2 decimal places

      return acc + total;
    }, 0);

    const newCount = this.currentVehicleCountValue + additionalCount;
    const newTotal = this.currentMonthlyTotalValue + additionalTotal;

    if (additionalCount > 0) {
      const newTotalFormatted = newTotal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      this.newVehiclesCountTarget.textContent = newCount;
      this.newMonthlyTotalTarget.textContent = `$${newTotalFormatted}`;

      this.currentTotalsTarget.classList.remove("hidden");
      this.newTotalsTarget.classList.remove("hidden");
    } else {
      this.currentTotalsTarget.classList.add("hidden");
      this.newTotalsTarget.classList.add("hidden");
    }
  }
}
