import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cron"
export default class extends Controller {
  static targets = ["repeats", "timeOfDay", "dayOfWeek", "dayOfMonth", "cadence"];

  connect() {
    const [minute, hour, dayOfMonth, monthOfYear, dayOfWeek] = this.cadenceTarget.value.split(" ");
    this.repeatsTarget.value = this.#repeatsFromCron(dayOfMonth, monthOfYear, dayOfWeek);
    this.timeOfDayTarget.value = `${hour}:${minute}`;
    this.dayOfWeekTarget.value = dayOfWeek === "*" ? undefined : dayOfWeek;
    this.dayOfMonthTarget.value = dayOfMonth === "*" ? undefined : dayOfMonth;
    this.updateFields();
  }

  onChangeTime() {
    this.calculateCadence();
  }

  onChangeRepeat() {
    this.updateFields();
    this.calculateCadence();
  }

  updateFields() {
    const repeatFrequency = this.repeatsTarget.value;
    this.dayOfWeekTarget.hidden = repeatFrequency !== "weekly";
    this.dayOfMonthTarget.hidden = !["monthly", "quarterly"].includes(repeatFrequency);
  }

  calculateCadence() {
    let cadence = "";
    const [hour, minutesInput] = this.timeOfDayTarget.value.split(":");
    const minutes = minutesInput === "30" ? "30" : "0";

    cadence += minutes + " " + hour;

    if (["monthly", "quarterly"].includes(this.repeatsTarget.value)) {
      cadence += " " + this.dayOfMonthTarget.value;
    } else {
      cadence += " *";
    }

    if (this.repeatsTarget.value === "weekly") {
      cadence += " * " + this.dayOfWeekTarget.value;
    } else if (this.repeatsTarget.value === "quarterly") {
      cadence += " */3 *";
    } else {
      cadence += " * *";
    }

    this.cadenceTarget.value = cadence; // Assuming you have an input to hold the cadence value
  }

  #repeatsFromCron(dayOfMonth, monthOfYear, dayOfWeek) {
    if (monthOfYear === "*/3") {
      return "quarterly";
    }

    if (dayOfMonth === "*") {
      return dayOfWeek === "*" ? "daily" : "weekly";
    }

    return "monthly";
  }
}
