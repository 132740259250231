import EventController from "../../event_controller";

export default class extends EventController {
  static values = {
    ...EventController.values,
    offerId: Number,
  };

  eventProperties(baseProperties, _eventType, _node, _target) {
    return { ...baseProperties, offer_id: this.offerIdValue };
  }
}
