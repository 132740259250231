import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "navigationPreviewButtonSelector",
    "navigationPreviewButtonIcon",
    "navigationPreviewButtonText",
    "previewButton",
  ];

  changeNavigationButtonColor(event) {
    this.navigationPreviewButtonSelectorTarget.style.backgroundColor = event.target.value;
    this.navigationPreviewButtonIconTarget.style.color = event.target.value;
    this.navigationPreviewButtonTextTarget.style.color = event.target.value;
  }

  changeButtonColor(event) {
    this.previewButtonTargets.forEach((button) => {
      button.style.backgroundColor = event.target.value;
    });
  }

  changeButtonTextColor(event) {
    this.previewButtonTargets.forEach((button) => {
      button.style.color = event.target.value;
    });
  }
}
