import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="card-connect"
export default class extends Controller {
  static outlets = ["payment-update"];
  static values = { cardConnectUrl: String };
  static targets = ["token", "expiry"];

  connect() {
    window.addEventListener("message", this.handleTokenEvent.bind(this), false);
  }

  disconnect() {
    window.removeEventListener("message", this.handleTokenEvent.bind(this), false);
  }

  handleTokenEvent(event) {
    const cardConnectUrl = this.cardConnectUrlValue;

    // Ensure that the frame messaging us (the event.origin) is the card connect frame
    // We are also sending iFrame Rinsed messages that we should ignore here
    if (cardConnectUrl.startsWith(event.origin)) {
      const { validationError, errorCode, token, expiry } = JSON.parse(event.data);

      // If the error is 0, then we save the token
      // If the message contains a validatione error, we prevent submitting the form
      // We can ignore all other messages (such as typing, stop typing, ...)
      if (errorCode === "0" && token) {
        this.tokenTarget.value = token;
        this.expiryTarget.value = expiry;
        this.paymentUpdateOutlet.enableSubmit();
      } else if (validationError) {
        this.tokenTarget.value = "";
        this.expiryTarget.value = "";
        this.paymentUpdateOutlet.disableSubmit();
      }
    }
  }
}
